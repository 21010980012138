<template>
  <div class="mb-32">

      <section class="pt-8 h-80 lg:h-screen bg-cover object-cover bg-center md:bg-fixed  background_image relative">
            <div class="absolute inset-0 h-full w-full opacity-75 bg-black"></div>

        <div class="text-white absolute inset-0 flex flex-col justify-center items-center w-full">
            <div class="container mx-auto px-6 xl:px-12">
              <h1 class="text-6xl md:text-9xl lg:text-10xl xl:text-12xl font-bold text-center">
                     {{ $t("Covid-19.title") }}     

 </h1>
              <div class="-mt-6 md:-mt-12 xl:-mt-24 text-center md:text-left max-w-xs lg:max-w-md ml-auto">
                <p class="text-xl xl:text-3xl  ">                     {{ $t("Covid-19.subtitle") }}     
</p>

              </div>
            </div>
        </div>
      </section>

      <section class="container mx-auto px-6 xl:px-12 mt-20 md:mt-32">
        <p class="text-xl md:text-2xl">
        {{ $t("Covid-19.section1") }}     
        </p>

        <p class="text-xl md:text-2xl mt-2">
        {{ $t("Covid-19.section2") }}     
        </p>

        <p class="text-xl md:text-2xl mt-6">
        {{ $t("Covid-19.section3") }}     
        </p>
        
      </section>

      <section class="container mx-auto px-6 xl:px-12 mt-20 md:mt-32 lg:grid-cols-3 grid gap-16 xl:gap-32">

          <div class="text-center">
            <p class="text-3xl md:text-5xl font-bold">
        {{ $t("Covid-19.section4") }}     
            </p>
            <svg class="mx-auto mt-8 md:mt-16" width="243.707" height="243.707" viewBox="0 0 243.707 243.707">
              <g id="Group_18" data-name="Group 18" transform="translate(-378.646 -1744.646)">
                <g id="Rectangle_39" data-name="Rectangle 39" transform="translate(379 1745)" fill="#fff" stroke="#707070" stroke-width="1">
                  <rect width="243" height="243" stroke="none"/>
                  <rect x="0.5" y="0.5" width="242" height="242" fill="none"/>
                </g>
                <path id="Path_44" data-name="Path 44" d="M9850,4582l243,243" transform="translate(-9471 -2837)" fill="none" stroke="#707070" stroke-width="1"/>
                <path id="Path_49" data-name="Path 49" d="M10093,4582l-243,243" transform="translate(-9471 -2837)" fill="none" stroke="#707070" stroke-width="1"/>
              </g>
            </svg>

            <p class="mt-8 md:mt-16 text-xl md:text-2xl">
        {{ $t("Covid-19.section5") }}     
            </p>
          </div>

          <div class="text-center">
            <p class="text-3xl md:text-5xl font-bold">
        {{ $t("Covid-19.section6") }}     
            </p>
            <svg class="mx-auto mt-8 md:mt-16" width="243.707" height="243.707" viewBox="0 0 243.707 243.707">
              <g id="Group_18" data-name="Group 18" transform="translate(-378.646 -1744.646)">
                <g id="Rectangle_39" data-name="Rectangle 39" transform="translate(379 1745)" fill="#fff" stroke="#707070" stroke-width="1">
                  <rect width="243" height="243" stroke="none"/>
                  <rect x="0.5" y="0.5" width="242" height="242" fill="none"/>
                </g>
                <path id="Path_44" data-name="Path 44" d="M9850,4582l243,243" transform="translate(-9471 -2837)" fill="none" stroke="#707070" stroke-width="1"/>
                <path id="Path_49" data-name="Path 49" d="M10093,4582l-243,243" transform="translate(-9471 -2837)" fill="none" stroke="#707070" stroke-width="1"/>
              </g>
            </svg>

            <p class="mt-8 md:mt-16 text-xl md:text-2xl">
        {{ $t("Covid-19.section7") }}     
            </p>
          </div>

          <div class="text-center">
            <p class="text-3xl md:text-5xl font-bold">
        {{ $t("Covid-19.section8") }}     
            </p>
            <svg class="mx-auto mt-8 md:mt-16" width="243.707" height="243.707" viewBox="0 0 243.707 243.707">
              <g id="Group_18" data-name="Group 18" transform="translate(-378.646 -1744.646)">
                <g id="Rectangle_39" data-name="Rectangle 39" transform="translate(379 1745)" fill="#fff" stroke="#707070" stroke-width="1">
                  <rect width="243" height="243" stroke="none"/>
                  <rect x="0.5" y="0.5" width="242" height="242" fill="none"/>
                </g>
                <path id="Path_44" data-name="Path 44" d="M9850,4582l243,243" transform="translate(-9471 -2837)" fill="none" stroke="#707070" stroke-width="1"/>
                <path id="Path_49" data-name="Path 49" d="M10093,4582l-243,243" transform="translate(-9471 -2837)" fill="none" stroke="#707070" stroke-width="1"/>
              </g>
            </svg>

            <p class="mt-8 md:mt-16 text-xl md:text-2xl">
          {{ $t("Covid-19.section9") }}     
            </p>
          </div>

      </section>

      <section class="container mx-auto px-6 xl:px-12 mt-20 md:mt-32 ">
        <div class="">
          <p class="text-3xl md:text-5xl xl:text-6xl font-bold">
         {{ $t("Covid-19.section10") }}   
                </p>
          <p class="mt-6 md:mt-10 xl:mt-16 text-xl md:text-2xl">
         {{ $t("Covid-19.section11") }}   
          </p>
        </div>

        <div class="mt-24">
          <p class="text-3xl md:text-5xl xl:text-6xl font-bold">
         {{ $t("Covid-19.section12") }}   
          </p>
          <p class="mt-6 md:mt-10 xl:mt-16 text-xl md:text-2xl">
         {{ $t("Covid-19.section13") }}   
          </p>
          <p class="text-xl md:text-2xl">
         {{ $t("Covid-19.section14") }}   
          </p>
          <p class="text-xl md:text-2xl">
         {{ $t("Covid-19.section15") }}   
          </p>
        </div>


        <div class="mt-24">
          <p class="text-3xl md:text-5xl xl:text-6xl font-bold">
         {{ $t("Covid-19.section16") }}   
          </p>
          <p class="mt-6 md:mt-10 xl:mt-16 text-xl md:text-2xl">
            
            <a target="_blank" class="underline" href="https://ncdc.org.ly/Ar/">
         {{ $t("Covid-19.section17") }}   
            </a>
          </p>
          <p class="text-xl md:text-2xl">
             
            <a target="_blank" class="underline" href="https://www.who.int/ar/emergencies/diseases/novel-coronavirus-2019?fbclid=IwAR0zKkKo55PjZui9qt53BRa3jWekKSTaQHzfHZHqMX_3DoCD13ldBNFGAJs">
         {{ $t("Covid-19.section18") }}   
            </a>
          </p>
          <p class="text-xl md:text-2xl">
            <a target="_blank" class="underline" href="https://www.unicef.org/coronavirus/covid-19?fbclid=IwAR1Kkt9iSSUXDZoo6DK85xbF1tlrBK76ibVTTCxREwlBdSFIZPZmXGctBag">
         {{ $t("Covid-19.section19") }}   
            </a>
          </p>
        </div>
      </section>
      
  </div>
</template>
<script>
export default {
  created() {
    scrollTo(0, 0);
  },
  data() {
    return {
      art:false,
      Animation:false,
      Design:false,
      Photography:false,
    };
  },
  
  methods: {
   
  },
};
</script>

<style >
.background_image{

    background-image:
    url("../assets/images/COVID-19.jpg");

  }
</style>